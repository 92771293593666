import * as Sentry from '@sentry/browser';
import 'nodelist-foreach-polyfill';
import axios from 'axios';
import AOS from 'aos';

Sentry.init({ dsn: 'https://3badc1bd4f13464b8156128aa5a04074@sentry.io/1392794' });

class App {
  constructor() {
    this.loadImages();

		this.$ = {
			elements: {
				form: document.querySelector('.form'),
			}
		}

    this.$.elements.form.addEventListener('submit', e => {
			e.preventDefault();

      this.submit();
		});

    AOS.init({
      once: true,
    });
  }

  loadImages() {
    const images = document.querySelectorAll('[data-image]');

    images.forEach(image => {
      const src = image.dataset.image;
      const Img = new Image();
      Img.onload = () => {
        image.src = src;
      };
      Img.src = src;
    });
  }

  submit() {
		const { form } = this.$.elements;
    const data = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      company: document.getElementById('company').value,
      subject: document.getElementById('subject').value,
      message: document.getElementById('message').value,
    };

    axios.post('email.php', data).then(response => {
			if(response.data.success) {
				form.classList.add('submitted', 'success');	
			} else {
				form.classList.add('submitted', 'failure');	
			}
    });
  }
}

new App();
